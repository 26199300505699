import PropTypes from "prop-types"
import React from "react"
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import About from './about'
import Logo from './logo'

const Header = ({ siteTitle }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <header>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ width: '98vw' }}
      >
        <Grid item sm={4} lg={3} xs={6} md={4} xl={2} style={{ padding: '10px', opacity: '1'}}>
          <Logo />
        </Grid>
        <Grid item>
          <IconButton
            aria-label="about"

            style={{
              color: '#00a8db',
              opacity: '1',
            }}
            onClick={handleClickOpen}
          >
            <MenuIcon fontSize="large"/>
          </IconButton>
        </Grid>
      </Grid>
      <About open={open} handleClose={handleClose} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
