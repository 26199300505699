import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LogoPromis from './logoPromis';
import LogoCity from './logoCity';

import './css/about.css'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.68)',
    alignItems: 'normal'
  },
  root: {
    width: "50%",
    margin: '5vh',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: "90%",
      margin: '2vh',
    },
  }
}));

export default function About(props) {
  const classes = useStyles();

  return (
    <Backdrop
      open={props.open}
      onClick={props.handleClose}
      className={classes.backdrop}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >



        <Grid item>
          <Typography variant="body1" gutterBottom paragraph>
          Створення місцевої радіостанції дає можливість жителям Миргородської територіальної громади та мешканцям сусідніх громад Миргородського району безкоштовно отримувати актуальну інформацію про важливі рішення місцевої влади, дізнаватися про головні новини та події на локальній FM-хвилі.
          </Typography>

          <Typography variant="body1" gutterBottom paragraph>
          Радіо «Миргород» охоплює площу близько 314 км2, це Миргородська територіальна громада, до якої у 2020 році увійшли місто Миргород та 11 найближчих сільських рад. 
          </Typography>

          <Typography variant="body1" gutterBottom paragraph>
          Слухайте Радіо «Миргород» цілодобово на хвилі 96.9 FМ. Щогодини про найактуальніше у випуску новин. Цікаві рубрики та найкраща українська музика.
          </Typography>
          <Typography variant="body1" gutterBottom paragraph>
            Контакти:
          </Typography>
          <div style={{ paddingLeft: '5px' }}>
            <Typography variant="body2" gutterBottom paragraph />

            <Typography variant="body2">
              Телефон редакції:
              </Typography>
            <Typography variant="body2">
              <Link href="tel:0535555311" variant="body2" >
                {'(05355) 5 53 11'}
              </Link>
            </Typography>


            <Typography variant="body2" gutterBottom paragraph />

            <Typography variant="body2">
              Електронна скринька:
              </Typography>
            <Typography variant="body2">
              <Link href="mailto:mirgorod_tv@ukr.net" variant="body2" >
                {'mirgorod_tv@ukr.net'}
              </Link>
            </Typography>


            <Typography variant="body2" gutterBottom paragraph />

            <Typography variant="body2">
              Директор:
            </Typography>
            <Typography variant="body2">
              Євген Ошека
            </Typography>
            <Typography variant="body2">
              <Link href="tel:0505684696" variant="body2" >
                {'050 56 84 696'}
              </Link>
            </Typography>


            <Typography variant="body2" gutterBottom paragraph />

            <Typography variant="body2">
              Редактор ФМ радіо:
            </Typography>
            <Typography variant="body2">
              Марина Усик
            </Typography>
            <Typography variant="body2">
              <Link href="tel:0637152529" variant="body2" >
                {' 063 715 25 29'}
              </Link>
            </Typography>


            <Typography variant="body2" gutterBottom paragraph />

            <Typography variant="body2" >
              Ми в соц. Мережах:
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.facebook.com/mirgorod.tv" variant="body2">
                {'Facebook'}
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.youtube.com/user/mirgorodTV" variant="body2">
                {'Youtube'}
              </Link>
            </Typography>

            <Typography variant="body2" gutterBottom paragraph />

            <Typography variant="body2">
              Адреса:
              </Typography>
            <Typography variant="body2" gutterBottom>
              Полтавська обл.  м. Миргород, вул. Незалежності, 2, 37600
            </Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
          style={{ width: '80%',}}
        >
          <Grid item xs={2}  >
            <a href="http://myrgorod.pl.ua/">
              <LogoCity />
            </a>
          </Grid>
          <Grid item xs={10}>
            <div style={{
              backgroundColor: 'white',
              padding: '5px',
              border: '3px white solid',
              borderRadius: '5px',
            }}>
              <a href="http://pleddg.org.ua/">
                <LogoPromis />
              </a>
            </div>
          </Grid>
        </Grid>
            <Typography variant="caption" paragraph style={{ fontSize: '0.6rem' }}>
              Веб-сайт розробленно за фінансування Уряду Канади через Фонд підтримки ініціатив з демократичного врядування та розвитку проекту міжнародної технічної допомоги «Партнерство для розвитку міст» (Проект ПРОМІС). Проект ПРОМІС впроваджує Федерація канадських муніципалітетів (ФКМ) за фінансової підтримки Міністерства міжнародних справ Канади. Контент веб-сайту є виключно думкою авторів та необов'язково відображає офіційну позицію Міністерства міжнародних справ Канади та ФКМ. Більше про Проект ПРОМІС на веб-сайті
            <Link href="http://pleddg.org.ua/ua/" variant="caption" style={{ fontSize: '0.6rem' }}>
                {' тут'}
              </Link>
            .
          </Typography>
          </div>
        </Grid>
      </Grid >
    </Backdrop >
  );
}
