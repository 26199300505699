/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import LogoPromis from './logoPromis'
import Header from "./header"
import Grid from '@material-ui/core/Grid'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        style={{ height: '100vh' }}
      >
        <Grid item xs>
          <Header siteTitle={data.site.siteMetadata.title} />
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ width: '98vw', marginTop: '3vh' }}
            item
          >
            <Grid item ><main>{children}</main></Grid>
          </Grid>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
